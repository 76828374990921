<script setup lang="ts">
import type { Filter } from '~/types/filter';

const emit = defineEmits<{
    toggle: (filter: Filter) => void;
}>();

const props = withDefaults(
    defineProps<{
        item: Filter;
        level?: number;
        ghost?: boolean;
        dismissable?: boolean;
        wrap?: boolean;
    }>(),
    {
        level: 1,
        ghost: false,
        dismissable: false,
        wrap: false,
    },
);

const wrapClass: { [key: number]: string } = {
    1: 'flex flex-col w-full mb-5',
    2: 'flex flex-row',
    3: 'flex flex-col',
    4: 'flex flex-row',
    5: 'flex flex-col',
};

const badgeClass: { [key: number]: string } = {
    1: 'badge-primary badge-lg',
    2: 'badge-secondary badge-md',
    3: 'badge-accent badge-sm',
    4: 'badge-base badge-sm',
    5: 'badge-base badge-sm',
};
</script>

<template>
    <div :class="wrap && wrapClass[level]">
        <span
            :class="[
                badgeClass[level],
                {'!badge-ghost': ghost || item.checked},
                'h-auto min-h-[1.25rem]'
            ]"
            class="group badge cursor-pointer mb-2 me-2 shadow-sm"
            @click="emit('toggle', {...item, checked: !ghost && !item.checked})"
        >
            <svg
                v-if="dismissable"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                class="inline-block w-4 h-4 stroke-current me-1 group-hover:stroke-error"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                />
            </svg>
            <span v-html="item.label"></span>
        </span>
    </div>
</template>
