<script setup lang="ts">
import type { Ref } from 'vue';
import FilterCheckbox from '~/components/ui/FilterCheckbox.vue';
import type { ActiveFilter, Filter } from '~/types/filter';
import { updatedFiltersWithTags } from '~/utils/client/useFilters';

const emit = defineEmits(['change', 'clear']);

const props = withDefaults(
    defineProps<{
        activeFilters: ActiveFilter[];
        activeFilterKeys: string[];
    }>(),
    {
        activeFilters: [],
        activeFilterKeys: [],
    },
);

const filters: Ref<Filter[]> = useState('filters', () => []);

onMounted(async () => {
    filters.value = [];
    for (const group of await updatedFiltersWithTags()) {
        if (group.id === 'tags') {
            group.filters = (group?.filters ?? []).filter(
                (f) => f.items?.length,
            );
        }
        filters.value.push(group);
    }
});
</script>

<template>
    <div class="w-full">
        <div class="mb-10">
            <div class="flex flex-row justify-between">
                <h3 class="text-2xl mb-5">
                    Vybrané
                </h3>
                <div class="tooltip" data-tip="Zrušit filtry">
                    <button
                        v-if="activeFilters.length"
                        class="btn btn-sm btn-rounded btn-link no-underline text-gray-300 hover:text-gray-700"
                        @click="emit('clear')"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            <div class="flex flex-row flex-wrap">
                <FilterCheckbox
                    v-for="{ level, item } in activeFilters"
                    :key="item.id"
                    :item="item"
                    :level="level"
                    :wrap="false"
                    dismissable
                    @toggle="emit('change', $event)"
                />
                <div v-if="!activeFilters.length" class="text-2xl text-gray opacity-20 font-light">
                    Žádné vybrané filtry
                </div>
            </div>
        </div>
        <div class="divider mb-10"></div>
        <div class="flex flex-row flex-wrap">
            <div
                v-for="filterGroup in filters"
                :key="filterGroup.label"
                class="min-w-[33%]"
            >
                <h3 class="text-2xl my-5">
                    {{ filterGroup.label }}
                </h3>
                <div v-for="filter in filterGroup.filters">
                    <FilterCheckbox
                        :key="filter.id"
                        :item="filter"
                        :ghost="activeFilterKeys.includes(filter.id)"
                        @toggle="emit('change', $event)"
                    />
                    <div v-if="filter.items?.length" class="flex flex-row flex-wrap">
                        <FilterCheckbox
                            v-for="sub in filter.items"
                            :key="sub.id"
                            :item="sub"
                            :level="2"
                            :ghost="activeFilterKeys.includes(sub.id)"
                            @toggle="emit('change', $event)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
