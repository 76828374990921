<script setup lang="ts">
import DrawerProfile from '~/components/ui/DrawerProfile.vue';
import HeroIcon from '~/components/ui/HeroIcon.vue';
import type { Profile } from '~/types/profileCard';

const props = defineProps<{
    profile: Profile;
}>();
const emit = defineEmits(['close']);

const dialogId = `profile-dialog-${props.profile.slug}`;
const route = useRoute();
const isOpen = ref(props.profile.slug === route.params.slug[0]);

const open = () => {
    isOpen.value = true;
};

const close = () => {
    isOpen.value = false;
    emit('close');
};
</script>

<template>
    <div>
        <slot :show-modal="open">
            <label :for="`checkbox-${dialogId}`" @click="open" class="btn btn-primary">Otevřít profil</label>
        </slot>

        <input :id="`checkbox-${dialogId}`" type="checkbox" class="modal-toggle" v-model="isOpen" />
        <div :id="dialogId" class="modal modal-bottom sm:modal-middle">
            <div class="modal-box sm:w-11/12 sm:max-w-[110ch] max-h-[75vh] sm:max-h-[90%] text-justify py-8 sm:px-10 sm:pt-20">
                <a href="#" @click.prevent="close" tabindex="-1" autofocus class="absolute top-4 right-4 cursor-pointer">
                    <HeroIcon name="XMarkIcon" class="w-8 h-8" />
                </a>
                <DrawerProfile v-if="isOpen" :assistant="profile" />
                <button class="btn btn-sm w-full btn-text" @click="close">Zavřít</button>
            </div>
            <form method="dialog" class="modal-backdrop">
                <button @click="close">Zavřít</button>
            </form>
        </div>
    </div>
</template>
