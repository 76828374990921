<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core';
import { $fetch } from 'ofetch';
import type { UseSeoMetaInput } from 'unhead/types';
import type { Ref } from 'vue';
import ErrorBoundary from '~/components/other/ErrorBoundary.vue';
import DrawerFilters from '~/components/ui/DrawerFilters.vue';
import FilterCheckbox from '~/components/ui/FilterCheckbox.vue';
import HeroIcon from '~/components/ui/HeroIcon.vue';
import PageDrawer from '~/components/ui/PageDrawer.vue';
import PageSection from '~/components/ui/PageSection.vue';
import ProfileCard from '~/components/ui/ProfileCard.vue';
import ProfileDialog from '~/components/ui/ProfileDialog.vue';
import type { ActiveFilter, Filter } from '~/types/filter';
import type { Profile } from '~/types/profileCard';
import { isFavorite } from '~/utils/client/favoriteAssistant';
import {
    silentRedirect,
    silentRedirectWithGtag,
} from '~/utils/client/silentRedirect';
import { updatedFiltersWithTags } from '~/utils/client/useFilters';

const assistants: Ref<Profile[]> = useState('assistants', () => []);
const filteredAssistants: Ref<Profile[]> = useState(
    'filteredAssistants',
    () => [],
);
const activeFilters = ref<ActiveFilter[]>([]);
const simpleFilters = ref<Filter[]>(await updatedFiltersWithTags());
const activeFilterKeys = computed(() => {
    const keys: string[] = [];
    for (const filter of activeFilters.value) {
        keys.push(filter.item.id);
        if (filter.item.items?.length) {
            keys.push(...filter.item.items.map((f) => f.id));
        }
    }
    return keys;
});

const fulltextSearch = ref('');
const fulltextSearchResults = ref<string[] | null>(null);

const sortAssistants = (a: Profile, b: Profile) => {
    if (a.isFavorite && !b.isFavorite) {
        return -2;
    }
    if (!a.isFavorite && b.isFavorite) {
        return 2;
    }

    return 0;
};

const filterAssistants = () => {
    if (
        activeFilters.value.length === 0 &&
        fulltextSearchResults.value === null
    ) {
        filteredAssistants.value = assistants.value;
        return;
    }

    filteredAssistants.value = [];

    assistantsLoop: for (const assistant of assistants.value) {
        filters: for (const filter of activeFilters.value) {
            if ((filter.item.items?.length ?? 0) > 0) {
                for (const subfilter of filter.item.items ?? []) {
                    if (
                        assistant.tags.some((t) => t.tag.key === subfilter.id)
                    ) {
                        continue filters;
                    }
                }
            }

            if (!assistant.tags.some((t) => t.tag.key === filter.item.id)) {
                continue assistantsLoop;
            }
        }

        if (fulltextSearchResults.value !== null) {
            if (!fulltextSearchResults.value.includes(assistant.id)) {
                continue;
            }
        }

        filteredAssistants.value.push(assistant);
    }
};

const searchWithFulltext = async () => {
    if (!fulltextSearch.value) {
        fulltextSearchResults.value = null;
        filterAssistants();
        return;
    }

    try {
        fulltextSearchResults.value = await $fetch<string>(
            `/api/catalog/fulltext?query=${fulltextSearch.value}`,
        );
    } catch (e) {
        console.error(e);
        fulltextSearchResults.value = [];
    }
    filterAssistants();
};
const fulltextSearchDebounced = useDebounceFn(searchWithFulltext, 500);
watch(fulltextSearch, async () => {
    await fulltextSearchDebounced();
});

const resetFulltext = () => {
    fulltextSearch.value = '';
    fulltextSearchResults.value = null;
    filterAssistants();
};

const toggleFilter = (filter: Filter, level = 1) => {
    if (filter.checked) {
        activeFilters.value.push({
            level,
            item: filter,
        });
    } else {
        activeFilters.value = activeFilters.value.filter(
            (f) => f.item.id !== filter.id,
        );
    }

    filterAssistants();
};

const clearFilter = () => {
    activeFilters.value = [];
    filterAssistants();
};

const fetchProfiles = async () => {
    const { data } = await useFetch<Profile[]>('/api/catalog');

    assistants.value = data.value ?? [];

    if (import.meta.client) {
        assistants.value = assistants.value
            .map((assistant) => {
                return {
                    ...assistant,
                    isFavorite: isFavorite(assistant),
                };
            })
            .sort(sortAssistants);
    }

    filterAssistants();
};

await fetchProfiles();

const route = useRoute();
const openProfile: Ref<Profile | null> = useState(
    'openProfile',
    () =>
        assistants.value.find(({ slug }) => slug === route.params.slug[0]) ??
        null,
);

watch(
    () => route.params.slug,
    async () => {
        await nextTick();
        const open =
            assistants.value.find(
                ({ slug }) => slug === route.params.slug[0],
            ) ?? null;

        if (open !== openProfile.value) {
            openProfile.value = open;
        }
    },
    { deep: true },
);

useHead({
    title: openProfile.value?.name ?? 'Katalog',
});

const router = useRoute();
const meta: UseSeoMetaInput = {
    ogUrl: `https://najdiasistentku.cz${router.fullPath}`,
};

if (openProfile.value?.caption) {
    meta.description = openProfile.value.caption;
    meta.ogDescription = openProfile.value.caption;
    meta.twitterDescription = openProfile.value.caption;
}

if (openProfile.value?.name) {
    meta.ogTitle = openProfile.value.name;
    meta.twitterTitle = openProfile.value.name;
}

if (openProfile.value?.image) {
    defineOgImageComponent('Catalog', {
        image: openProfile.value.image,
        domain: 'https://najdiasistentku.cz',
    });
}

useSeoMeta(meta);

const onProfileClick = (assistant: Profile, showModal) => {
    useHead({
        title: openProfile.value?.name ?? 'Katalog',
    });
    silentRedirectWithGtag(`/katalog/${assistant.slug}`);
    showModal();
};
const onFiltersDrawerStateChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    filtersOpen.value = target.checked;
};

const filtersOpen = ref(false);

onMounted(async () => {
    setTimeout(() => {
        if (import.meta.client && window.location.hash) {
            const button = document.querySelector(window.location.hash);
            if (button) {
                const rect = button.getBoundingClientRect();
                const scrollTop =
                    window.scrollY +
                    rect.top -
                    window.innerHeight / 2 +
                    rect.height / 2;
                const scrollLeft =
                    window.scrollX +
                    rect.left -
                    window.innerWidth / 2 +
                    rect.width / 2;
                window.scrollTo({
                    top: scrollTop,
                    left: scrollLeft,
                    behavior: 'instant',
                });

                setTimeout(() => {
                    button.focus();
                }, 300);
            }
        }
    }, 100);
});
</script>

<template>
    <div>
        <PageSection id="katalog" class="lg:!py-32">
            <h1 class="text-4xl text-center mb-10 sm:mb-20">
                Katalog
            </h1>
            <div class="w-full mb-5 flex gap-5 justify-center flex-col md:flex-row">
                <div class="w-full max-w-2xl">
                    <div>
                        <label class="input input-sm sm:input-md text-base !outline-0 input-bordered border-b-0 flex items-center gap-2 join-item rounded-lg rounded-b-none">
                            <HeroIcon
                                v-if="fulltextSearch.length > 0"
                                name="XMarkIcon"
                                class="w-4 h-4"
                                @click="resetFulltext"
                            />
                            <input
                                v-model="fulltextSearch"
                                type="text"
                                class="grow"
                                placeholder="Hledej.."
                            />
                            <HeroIcon name="MagnifyingGlassIcon" class="w-4 h-4" />
                        </label>
                    </div>
                    <div class="join join-horizontal rounded-t-none rounded-lg w-full">
                        <div
                            v-for="group in simpleFilters"
                            :key="group.id"
                            class="dropdown join-item grow"
                        >
                            <label
                                :id="group.groupId"
                                tabindex="0"
                                class="btn btn-sm sm:btn-md btn-primary join-item text-[12px] sm:text-inherit w-full"
                            >
                                <span class="hidden sm:block">{{ group.label }}</span>
                                <span class="block sm:hidden" v-html="group.smLabel ?? group.label"></span>
                            </label>
                            <ul tabindex="0" class="p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box w-64 -ml-12">
                                <li
                                    v-for="filter in group.filters"
                                    :key="filter.id"
                                    class="mb-2"
                                >
                                    <!-- eslint-disable vue/no-v-html -->
                                    <button
                                        class="btn btn-xs text-xs px-5 normal-case flex flex-row justify-start"
                                        :class="{'border-primary border-1': activeFilterKeys.includes(filter.id)}"
                                        @click="toggleFilter({ ...filter, checked: !activeFilterKeys.includes(filter.id) })"
                                        v-html="filter.label"
                                    >
                                    </button>
                                    <!-- eslint-enable vue/no-v-html -->
                                </li>
                            </ul>
                        </div>
                        <div class="grow">
                            <button class="btn btn-xs sm:btn-md btn-base join-item w-full h-[32px]" @click="filtersOpen = !filtersOpen">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-6 h-6"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                                    />
                                </svg>
                                <span class="hidden sm:block">Pokročilé filtry</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="activeFilters.length > 0" class="w-full flex justify-center flex-wrap">
                <div class="me-3 mb-2">
                    Vybrané filtry:
                </div>
                <FilterCheckbox
                    v-for="{item} in activeFilters"
                    :key="item.id"
                    :item="item"
                    :level="1"
                    :wrap="false"
                    dismissable
                    ghost
                    @toggle="toggleFilter"
                />
            </div>

            <ErrorBoundary class="w-full h-full">
                <MasonryWall
                    class="w-full content-center mt-5 sm:mt-20"
                    :items="filteredAssistants"
                    :column-width="384"
                    :gap="48"
                    :key-mapper="(item: Profile) => item.slug"
                >
                    <template #default="{ item }">
                        <ProfileDialog :profile="item" @close="silentRedirect('/katalog')">
                            <template #default="{ showModal }">
                                <ProfileCard
                                    :assistant="item"
                                    @click="onProfileClick(item, showModal)"
                                />
                            </template>
                        </ProfileDialog>
                    </template>
                </MasonryWall>
            </ErrorBoundary>
        </PageSection>
        <PageDrawer
            id="filters"
            :open="filtersOpen"
            @change="onFiltersDrawerStateChange"
        >
            <DrawerFilters
                :active-filters="activeFilters"
                :active-filter-keys="activeFilterKeys"
                @change="toggleFilter"
                @clear="clearFilter"
            />
        </PageDrawer>
    </div>
</template>
