<script setup lang="ts">
import HeroIcon from '~/components/ui/HeroIcon.vue';
import { removeFavorite, setFavorite } from '~/utils/client/favoriteAssistant';
import plural from '~/utils/client/plural';

enum TooltipPosition {
    top = 'tooltip-top',
    right = 'tooltip-right',
    bottom = 'tooltip-bottom',
    left = 'tooltip-left',
}

withDefaults(
    defineProps<{
        assistant: {
            capacity: number | null;
        };
        tooltipPosition?: TooltipPosition;
    }>(),
    {
        tooltipPosition: TooltipPosition.top,
    },
);
</script>

<template>
    <div v-if="assistant.capacity !== null">
        <span
            v-if="assistant.capacity <= 0"
            class="tooltip cursor-pointer"
            :class="TooltipPosition[tooltipPosition]"
            :data-tip="`Asistentka je momentálně plně vytížená`"
            @click.stop="removeFavorite(assistant)"
        >
            <HeroIcon name="ClockIcon" class="w-6 h-6 text-gray-400" solid/>
        </span>
        <span
            v-else-if="assistant.capacity <= 8"
            class="tooltip cursor-pointer"
            :class="TooltipPosition[tooltipPosition]"
            :data-tip="`Dostupná až ${assistant.capacity} ${plural(assistant.caption, 'hodinu', 'hodiny', 'hodin')} týdně`"
            @click.stop="removeFavorite(assistant)"
        >
            <HeroIcon name="ClockIcon" class="w-6 h-6 text-success opacity-40" solid/>
        </span>
        <span
            v-else-if="assistant.capacity <= 20"
            class="tooltip cursor-pointer"
            :class="TooltipPosition[tooltipPosition]"
            :data-tip="`Dostupná až ${assistant.capacity} hodin týdně`"
            @click.stop="removeFavorite(assistant)"
        >
            <HeroIcon name="ClockIcon" class="w-6 h-6 text-success opacity-70" solid/>
        </span>
        <span
            v-else-if="assistant.capacity > 20"
            class="tooltip cursor-pointer"
            :class="TooltipPosition[tooltipPosition]"
            :data-tip="`Dostupná až ${assistant.capacity} hodin týdně`"
            @click.stop="removeFavorite(assistant)"
        >
            <HeroIcon name="ClockIcon" class="w-6 h-6 text-success opacity-100" solid/>
        </span>
    </div>
</template>
